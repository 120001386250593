@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,600;0,700;1,400&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.print-style {
  display: block !important;
  -webkit-print-color-adjust: exact !important;
  print-color-adjust: exact !important;
}
@page {
  margin: 0;
}

thead tr th:first-child,
tbody tr td:first-child {
  min-width: 15rem;
  max-width: 15rem;
  /* word-break: break-all; */
}

@media print {
  .watermark {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(-45deg);
    opacity: 0.1;
    z-index: 1;
  }
  .pagebreak {
    page-break-before: always;
  }
  .print-section {
    page-break-inside: avoid;
  }

  /* Hides the table head after the first page */
  thead {
    display: table-row-group;
  }

  /* Remove extra margins & paddings */
  .ql-editor {
    margin: 0 !important;
    padding: 0 !important;
    min-height: auto !important;
    max-height: none !important;
    overflow: visible !important;
  }
}

ol {
  list-style: auto;
  margin: 0 !important;
  padding: 30px !important;
}

ol li {
  margin-bottom: 10px;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.ql-editor {
  min-height: 10rem;
}

.custom-ul {
  ul {
    list-style: disc;
    margin: 0 !important;
    padding: 20px !important;
  }

  ul li {
    margin-bottom: 8px;
  }
}
